import React, { Fragment } from 'react';
import styled from 'styled-components';

const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

const ProgrammeList = styled.ul`
    list-style-type: disc;

    & li {
        margin-bottom: 4px;
    }

    & ul {
        list-style-type: circle;
        margin-top: 2px;
    }
`;

export function Performance(props) {
    const date = new Date(props.date.replace(' ', 'T') + ':00Z');
    const day = `${months[date.getUTCMonth()]} ${date.getUTCDate()}`;
    const year = date.getUTCFullYear();

    let hours = date.getUTCHours();
    let minutes = date.getUTCMinutes();
    let suffix = 'am';

    if (hours >= 12) {
        suffix = 'pm';
    }

    if (hours > 13) {
        hours = hours - 12;
    }

    if (hours === 0) {
        hours = 12;
    }

    const time = `${hours}${
        minutes ? '.' + String(minutes).padStart(2, '0') : ''
    }${suffix}`;

    return (
        <li
            style={{
                marginBottom: '56px',
            }}
        >
            <div style={{ display: 'flex' }}>
                <div
                    style={{
                        textAlign: 'right',
                        paddingRight: '16px',
                        minWidth: '120px',
                    }}
                >
                    <span
                        style={{
                            position: 'relative',
                            right: '-6px',
                        }}
                    >
                        {day},
                    </span>
                    <br />
                    {year}
                    <br />
                    <br />
                    {time}
                </div>
                <div
                    style={{
                        paddingLeft: '16px',
                        borderLeft: '2px solid #ccc',
                        minWidth: '120px',
                    }}
                >
                    <h3
                        style={{
                            margin: '0',
                            fontSize: '18px',
                        }}
                    >
                        {props.name}
                    </h3>
                    <span style={{ fontStyle: 'italic' }}>{props.place}</span>
                    <br />
                    <br />
                    {props.description && (
                        <Fragment>
                            {props.description}
                            <br />
                            <br />
                        </Fragment>
                    )}
                    {props.programme && (
                        <Fragment>
                            Programme:
                            <br />
                            <ProgrammeList>{props.programme}</ProgrammeList>
                        </Fragment>
                    )}
                    {props.notes && (
                        <span style={{ fontStyle: 'italic', fontSize: '14px' }}>
                            {props.notes}
                        </span>
                    )}
                </div>
            </div>
        </li>
    );
}

export function PerformanceList(props) {
    return (
        <ul
            style={{
                listStyle: 'none',
                padding: '0',
                marginLeft: '0',
                marginBottom: '16px',
                ...props.style,
            }}
        >
            {props.children}
        </ul>
    );
}
