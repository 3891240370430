import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

const StyledImage = styled(GatsbyImage)`
    img {
        opacity: 1 !important;
    }
`;

const Image = () => (
    <StaticQuery
        query={graphql`
            query {
                placeholderImage: file(relativePath: { eq: "about.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(layout: CONSTRAINED, width: 400)
                    }
                }
            }
        `}
        render={(data) => (
            <StyledImage
                image={data.placeholderImage.childImageSharp.gatsbyImageData}
            />
        )}
    />
);
export default Image;
