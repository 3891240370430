import React from 'react';
import styled from 'styled-components';
import Image from './image';
import { Centered, CiteTitle } from './styled';

const AboutContainer = styled.div`
    display: flex;
    flex-direction: row-reverse;

    @media (max-width: 680px) {
        display: block;
    }
`;

const ImageWrapper = styled.div`
    flex: 1;
    margin-left: 32px;

    @media (max-width: 680px) {
        width: 100%;
        margin: 0 auto 16px;
    }
`;

const StickyDiv = styled.div`
    position: sticky;
    top: 64px;
    bottom: 200px;
`;

const About = () => (
    <Centered>
        <div id="about" style={{ position: 'relative', top: '-96px' }} />
        <h1 style={{ marginTop: '24px' }}>About</h1>
        <AboutContainer>
            <ImageWrapper>
                <StickyDiv>
                    <Image style={{ display: 'inline-block' }} />
                </StickyDiv>
            </ImageWrapper>
            <div style={{ flex: 1 }}>
                <p>
                    Pianist Anna Schreider was born on April 25, 1989 in Moscow
                    and started learning music at the age of five at the Moscow
                    Gnessin Music School. Later she continued her music studies
                    at the Academic Music Conservatory College and Gnessin
                    Russian Academy of Music, where she completed her
                    undergraduate studies in 2014 and then postgraduate in 2016
                    with first class honours, studying under Alexander Kobrin
                    and then Pavel Dombrovsky.
                </p>
                <p>
                    In 2017 Anna won the Sidney Kenneth Brindle Award and the
                    Sara Krein Scholarship to study at the Royal Academy of
                    Music in London, and graduated in 2019 with a Master of Arts
                    degree.
                </p>
                <p>
                    Anna is a laureate of many International competitions,
                    including: the UK (3rd prize winner at the International
                    Music Competition, London), Italy (1st prize winner in both
                    solo and ensemble categories,{' '}
                    <CiteTitle>Don Vincenzo Vitti</CiteTitle>
                    International Competition, Castellana Grotte) and Portugal
                    (1st prize winner in both solo and ensemble categories,{' '}
                    <CiteTitle>21 Century Art</CiteTitle> International Music
                    Competition, Funchal, Madeira). Anna is also a Laureate of
                    the very prestigious President’s Prize in Russia.
                </p>

                <p>
                    Anna performs regularly in Russia and abroad, having
                    previously appeared at St Martin-in-the-Fields both as a
                    soloist and ensemblist.
                </p>

                <p>
                    Anna is a keen chamber musician with a large cello, flute
                    and vocal repertoire and has worked as an accompanist in the
                    cello and conducting classes at the Gnessin Academy of
                    music, and has accompanied violinists in Gyorgy Pauk’s
                    violin class at the Royal Academy of Music. She regularly
                    performs in a piano duo ensemble with her former Gnessin
                    Academy chamber music professor, Elena Bagrova.
                </p>

                <p>
                    Anna is currently teaching piano performance at the Letovo
                    school for gifted children in Moscow, Russia.
                </p>
            </div>
        </AboutContainer>
    </Centered>
);

export default About;
