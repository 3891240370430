import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

const StyledImage = styled(GatsbyImage)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
    height: 100vh;

    img {
        object-fit: cover !important;
        font-family: 'object-fit: cover !important; object-position: 50% 50% !important;'; // needed for IE9+ polyfill
        opacity: 1 !important;
        animation: fadein 500ms;
    }

    @keyframes fadein {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    @media (max-aspect-ratio: 16 / 12) {
        img {
            object-position: 78% 50% !important;
            font-family: 'object-fit: cover !important; object-position: 78% 50% !important;'; // needed for IE9+ polyfill
        }
    }

    @media (max-width: 680px) {
        img {
            top: -24px !important;
        }
    }
`;

const HeroImage = () => (
    <StaticQuery
        query={graphql`
            query {
                placeholderImage: file(
                    relativePath: { eq: "hero-background.jpg" }
                ) {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH, quality: 100)
                    }
                }
            }
        `}
        render={(data) => (
            <StyledImage
                image={data.placeholderImage.childImageSharp.gatsbyImageData}
                alt="Anna Schreider standing by a grand piano"
            />
        )}
    />
);

export default HeroImage;
