import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Centered, CiteTitle } from '../components/styled';

import HeroImage from '../components/HeroImage';
import SEO from '../components/seo';
import About from '../components/About';
import { Performance, PerformanceList } from '../components/Performance';

const HeroLink = styled(Link)`
    font-family: sans-serif;
    font-size: 16px;
    margin-right: 16px;
    color: white;
    text-decoration: none;

    @media (max-width: 360px) {
        margin-right: 12px;
    }
`;

const HeroText = styled.div`
    color: white;
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
    position: absolute;
    bottom: 96px;
    left: 64px;

    @media (max-width: 1024px) {
        bottom: 128px;
        left: 24px;
    }
`;

const HeroTitle = styled.h1`
    font-size: 72px;
    margin-bottom: 8px;

    @media (max-width: 360px) {
        font-size: 64px;
    }
`;

const Hero = () => (
    <div
        id="hero"
        style={{
            position: 'relative',
            height: '100vh',
            overflow: 'hidden',
        }}
    >
        <HeroImage />
        <HeroText>
            <HeroTitle>Anna Schreider</HeroTitle>
            <nav>
                <HeroLink to="#about">About</HeroLink>
                <HeroLink to="#upcoming">Upcoming</HeroLink>
                <HeroLink to="/photos">Photos</HeroLink>
                <HeroLink to="/recordings">Recordings</HeroLink>
            </nav>
        </HeroText>
    </div>
);

const IndexPage = () => (
    <div style={{ minHeight: '100vh', position: 'relative' }}>
        <SEO />
        <Hero />
        <div
            style={{
                position: 'relative',
                minHeight: '100vh',
            }}
        >
            <Centered>
                <div
                    id="upcoming"
                    style={{ position: 'relative', top: '-96px' }}
                />
                <h1 style={{ marginTop: '32px' }}>Upcoming</h1>
                <h2 style={{ fontStyle: 'italic', marginBottom: '32px' }}>To be announced</h2>
                <h1>Previous performances</h1>
                <PerformanceList>
                    <Performance
                        date="2024-05-19 19:00"
                        name="Crescendo of Classics"
                        place={
                            <Fragment>
                                Steinway Hall
                                <br />
                                Showroom 33, Str. 8, Al Quoz 1
                                <br />
                                Dubai, UAE
                            </Fragment>
                        }
                        description="In collaboration with Arina Yurasova (soprano) and Alina Vasina (mezzo-soprano)"
                        programme={
                            <li>
                                Arias and duets from masterpieces by Rossini, Bizet, Tchaikovsky and other composers
                            </li>
                        }
                    />
                    <Performance
                        date="2024-05-11 14:00"
                        name="Lunchtime concert. Woodwind Spirits"
                        place={
                            <Fragment>
                                Steinway Hall
                                <br />
                                Showroom 33, Str. 8, Al Quoz 1
                                <br />
                                Dubai, UAE
                            </Fragment>
                        }
                        description="Francesca Bruno (flute), Polina Maslova (oboe), Sofia Grishina (clarinet), and Anna Schreider (piano)"
                        programme={
                            <Fragment>
                                <li>
                                    J. Brahms, R. Schumann, F. Danzi, C. Franck, C. Saint-Saëns, D. Milhaud
                                </li>
                            </Fragment>
                        }
                        notes={
                            <>
                                Free entry. Please RSVP to{' '}
                                <a href="mailto:sofaclarinet@gmail.com">sofaclarinet@gmail.com</a>
                            </>
                        }
                    />
                </PerformanceList>
                <Link
                    to="/performances"
                    style={{
                        marginBottom: '64px',
                    }}
                >
                    View more previous performances
                </Link>
            </Centered>
            <About />
        </div>
    </div>
);

export default IndexPage;
